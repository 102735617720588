import React from 'react';
import {graphql, navigate} from 'gatsby';
import {useTranslation} from 'react-i18next';
// import PropTypes from 'prop-types';
import Spacer from '../../components/Spacer';
import PageDivider from '../../components/PageDivider';
import Utils from '../../util/util';
import SectionHeader from '../../components/SectionHeader';
import ContentContainer from '../../components/ContentContainer';
import Button from '../../components/Button';
import configs from '../../configs';
import constants from '../../constants';

const AutoParts = ({data}) => {
  const {t} = useTranslation();
  const _onClickViewList = () => {
    if (!Utils.isBrowser) return;
    window.open(configs.autoPartsDriveUrl, '_blank');
  };

  const _onClickContact = () => {
    navigate('/Contact', {
      state: {
        queryType: constants.QUERY_TYPE.AUTO_PARTS_PURCHASE,
      },
    });
  };

  return (
    <>
      <Spacer />
      <PageDivider image1={Utils.getGatsbyImageData(data)} />
      <ContentContainer>
        <div className={'py-10 lg:py-20'}>
          <SectionHeader
            title={'Auto Parts'}
            subTitle={
              'Please check the PDF from our DropBox, and inform us with the items you are interested.'
            }
          />
          <div>
            <Button
              title={t('button.view-list')}
              className={'mr-4 mb-4'}
              onClickBtn={_onClickViewList}
            />
            <Button
              title={t('button.contact-us')}
              onClickBtn={_onClickContact}
              second
            />
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default AutoParts;

export const query = graphql`
  query AutoPartsQuery {
    file(name: {eq: "service03"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
  }
`;

AutoParts.propTypes = {};
AutoParts.defaultProps = {};
