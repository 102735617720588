import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({height}) => {
  return <div className={'w-full'} style={{height}} />;
};

export default Spacer;

Spacer.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Spacer.defaultProps = {
  height: '8vh',
};
