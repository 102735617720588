import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Header from './Typograhpy/Header';
import Subtitle from './Typograhpy/Subtitle';

const variants = {
  show: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 20,
    opactiy: 0,
  },
};

const SectionHeader = ({
  title,
  subTitle,
  smallMargin,
  noMargin,
  id,
  showHeader,
}) => {
  const c = noMargin ? 'mb-0' : smallMargin ? 'mb-8' : 'mb-16';

  return (
    <div className={c} id={id}>
      <Header value={_.toUpper(title)} isShow={showHeader} />
      {subTitle && (
        <Subtitle
          value={subTitle}
          className={noMargin ? '' : 'my-5'}
          showSubHeader={showHeader}
        />
      )}
    </div>
  );
};

export default SectionHeader;

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  smallMargin: PropTypes.bool,
  noMargin: PropTypes.bool,
  id: PropTypes.string,
  showHeader: PropTypes.bool,
};
SectionHeader.defaultProps = {
  subTitle: '',
  smallMargin: false,
  noMargin: false,
  id: null,
  showHeader: true,
};
