import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';

const variants = {
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.15,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
};

const Subtitle = ({value, bold, className, showSubHeader}) => {
  return (
    <motion.h3
      className={`text-white text-base md:text-lg ${
        bold ? 'font-bold' : ''
      } ${className}`}
      variants={variants}
      animate={showSubHeader ? 'show' : 'hidden'}
    >
      {value}
    </motion.h3>
  );
};

export default Subtitle;

Subtitle.propTypes = {
  value: PropTypes.string,
  bool: PropTypes.bool,
  className: PropTypes.string,
  showSubHeader: PropTypes.bool,
};
Subtitle.defaultProps = {
  value: '',
  bool: false,
  className: '',
  showSubHeader: true,
};
