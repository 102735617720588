const LANGUAGE = Object.freeze({
  EN: 'en-US',
  ZH_HK: 'zh-Hant',
});

const CONTENTFUL_LOCALE_MAPPING = Object.freeze({
  [LANGUAGE.EN]: 'en-US',
  [LANGUAGE.ZH_HK]: 'zh-Hant-HK',
});

const CONTENTFUL_GRAPHQL = Object.freeze({
  ORDER: {
    FIRST_PUBLISHED_AT_ASC: 'sys_firstPublishedAt_ASC',
    FIRST_PUBLISHED_AT_DESC: 'sys_firstPublishedAt_DESC',
  },
});

const QUERY_TYPE = Object.freeze({
  GENERAL: 'general',
  CAR_PURCHASE: 'car_purchase',
  AUTO_PARTS_PURCHASE: 'auto_parts_purchase',
});

const CAR_STATUS = Object.freeze({
  SOLD: 'sold',
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
});

const constants = {
  LANGUAGE,
  CONTENTFUL_LOCALE_MAPPING,
  CONTENTFUL_GRAPHQL,
  QUERY_TYPE,
  CAR_STATUS,
};

export default constants;
